<template>
  <div>
    <el-tabs ref="tabs" @tab-click="handleTabClick">
      <el-tab-pane :label="$t('yearCheck.all')">
        <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="yearChecks" @clear="clearDateRange">
          <template slot="adSearch">
            <vm-search :label="$t('common.filter')">
              <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
            </vm-search>
            <vm-search :label="$t('yearCheck.status')">
              <el-select v-model.trim="search.status" :placeholder="$t('common.pleaseSelect')" style="width: 100%"
                clearable>
                <el-option v-for="item in statusList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </vm-search>
            <vm-search v-if="$i18n.isCn" :label="$t('elevator.accessPlatform')">
              <el-select v-model="search.integrationKey" :placeholder="$t('elevator.accessPlatform')"
                :style="{ width: $l('160px', '150px') }" clearable>
                <el-option v-for="item in integrationList" :key="item.integrationKey" :label="item.name"
                  :value="item.integrationKey"></el-option>
              </el-select>
            </vm-search>
            <vm-search :label="$t('yearCheck.checkDate')">
              <el-date-picker v-model="dateRange" value-format="yyyy-MM-dd" type="daterange"
                :range-separator="$t('common.to')" :start-placeholder="$t('common.startDate')"
                :end-placeholder="$t('common.endDate')" :default-time="['00:00:00', '23:59:59']"
                @change="handleDateChange">
              </el-date-picker>
            </vm-search>
          </template>
          <template slot="toolbar">
            <el-button v-if="editAuth" class="addBtn" @click="$refs.editPage.open(0)">
              {{ $t("common.add") }}
            </el-button>
            <el-button class="greenBtn" :loading="exportLoading" @click="exportExcel">
              {{ $t("common.export") }}
            </el-button>
          </template>
          <el-table-column prop="yearCheckNo" :label="$t('yearCheck.yearCheckNo')" width="180"
            align="center"></el-table-column>
          <el-table-column prop="elevatorNo" :label="$t('elevator.elevatorNo')" width="120"
            align="center"></el-table-column>
          <el-table-column prop="regNo" :label="$t('elevator.registerCode')" width="180"></el-table-column>
          <el-table-column prop="elevatorName" show-overflow-tooltip :label="$t('elevator.name')"></el-table-column>
          <el-table-column prop="useUnitName" :label="$t('elevator.useUnit')" show-overflow-tooltip></el-table-column>
          <el-table-column prop="quantityOrganName" :label="$t('elevator.qualityOrgan')"
            show-overflow-tooltip></el-table-column>
          <el-table-column prop="maintenanceComName" :label="$t('elevator.maintenanced')"
            show-overflow-tooltip></el-table-column>
          <el-table-column prop="checkDate" :label="$t('yearCheck.checkDate')" width="140"
            align="center"></el-table-column>
          <el-table-column prop="status" :label="$t('yearCheck.status')" width="100" align="center">
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.status === 1">{{ $t("yearCheck.result.pass") }}</el-tag>
              <el-tag type="danger" v-else>{{ $t("yearCheck.result.noPass") }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column v-if="editAuth" :label="$t('common.operate')" width="100" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" @click="handleEdit(scope.row)">{{ $t("common.edit") }}</el-button>
              <el-button type="text" class="operateDelBtn" @click="handleDelete(scope.row)">{{ $t("common.delete")
                }}</el-button>
            </template>
          </el-table-column>
        </vm-table>
      </el-tab-pane>
      <el-tab-pane>
        <span slot="label">
          {{ $t("yearCheck.notice") }}
          <el-badge :value="noticeTotal" :max="99" class="item"></el-badge>
        </span>
        <vm-table ref="noticeTable" v-loading="noticeLoading" :filter.sync="searchNotice" url="yearChecks"
          @clear="clearNoticeDateRange">
          <template slot="adSearch">
            <vm-search :label="$t('maintWorkOrder.filter')">
              <el-input v-model.trim="searchNotice.filter" :placeholder="$t('common.search')" clearable></el-input>
            </vm-search>
            <vm-search :label="$t('yearCheck.checkDate')">
              <el-date-picker v-model="noticeDateRange" value-format="yyyy-MM-dd" type="daterange"
                :range-separator="$t('common.to')" :start-placeholder="$t('common.startDate')"
                :end-placeholder="$t('common.endDate')" :default-time="['00:00:00', '23:59:59']"
                @change="handleNoticeDateChange">
              </el-date-picker>
            </vm-search>
          </template>
          <el-table-column prop="elevatorNo" :label="$t('elevator.elevatorNo')" width="120"
            align="center"></el-table-column>
          <el-table-column prop="regNo" :label="$t('elevator.registerCode')" width="180"></el-table-column>
          <el-table-column prop="elevatorName" :label="$t('elevator.name')" show-overflow-tooltip></el-table-column>
          <el-table-column prop="useUnitName" :label="$t('elevator.useUnit')" show-overflow-tooltip></el-table-column>
          <el-table-column prop="quantityOrganName" :label="$t('elevator.qualityOrgan')"
            show-overflow-tooltip></el-table-column>
          <el-table-column prop="maintenanceComName" :label="$t('elevator.maintenanced')"
            show-overflow-tooltip></el-table-column>
          <el-table-column prop="checkDate" :label="$t('yearCheck.checkDate')" width="100"
            align="center"></el-table-column>
          <el-table-column v-if="editAuth" :label="$t('common.operate')" width="80" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="handleCheck(scope.row)">{{$t("common.AnnualInspection")}}</el-button>
            </template>
          </el-table-column>
        </vm-table>
      </el-tab-pane>
      <el-tab-pane>
        <span slot="label">
          {{ $t("yearCheck.expired") }}
          <el-badge :value="expiredTotal" :max="99" class="item"></el-badge>
        </span>
        <vm-table ref="expiredTable" v-loading="expiredLoading" :filter.sync="searchExpired" url="yearChecks/expired"
          @clear="clearExpiredDateRange">
          <template slot="adSearch">
            <vm-search :label="$t('maintWorkOrder.filter')">
              <el-input v-model.trim="searchExpired.filter" :placeholder="$t('common.search')" clearable></el-input>
            </vm-search>
            <vm-search :label="$t('yearCheck.checkDate')">
              <el-date-picker v-model="expiredDateRange" value-format="yyyy-MM-dd" type="daterange"
                :range-separator="$t('common.to')" :start-placeholder="$t('common.startDate')"
                :end-placeholder="$t('common.endDate')" :default-time="['00:00:00', '23:59:59']"
                @change="handleExpiredDateChange">
              </el-date-picker>
            </vm-search>
          </template>
          <el-table-column prop="elevatorNo" :label="$t('elevator.elevatorNo')" width="120"
            align="center"></el-table-column>
          <el-table-column prop="regNo" :label="$t('elevator.registerCode')" width="180"></el-table-column>
          <el-table-column prop="elevatorName" :label="$t('elevator.name')" show-overflow-tooltip></el-table-column>
          <el-table-column prop="useUnitName" :label="$t('elevator.useUnit')" show-overflow-tooltip></el-table-column>
          <el-table-column prop="quantityOrganName" :label="$t('elevator.qualityOrgan')"
            show-overflow-tooltip></el-table-column>
          <el-table-column prop="maintenanceComName" :label="$t('elevator.maintenanced')"
            show-overflow-tooltip></el-table-column>
          <el-table-column prop="checkDate" :label="$t('yearCheck.checkDate')" width="100"
            align="center"></el-table-column>
          <el-table-column v-if="editAuth" :label="$t('common.operate')" width="80" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="handleCheck(scope.row)">{{$t("common.AnnualInspection")}}</el-button>
            </template>
          </el-table-column>
        </vm-table>

      </el-tab-pane>
    </el-tabs>
    <edit-dialog ref="editPage" @save-success="getList(1)"></edit-dialog>
  </div>
</template>
<script>
import EditDialog from "./YearCheckEdit.vue";

const moduleName = "yearChecks";

export default {
  components: { EditDialog },
  data() {
    return {
      editAuth: this.$auth(133),
      loading: false,
      noticeLoading: false,
      noticeTotal: 0,
      expiredLoading: false,
      expiredTotal: 0,
      dateRange: [],
      noticeDateRange: [],
      expiredDateRange: [],
      integrationList: [],
      exportLoading: false,
      search: {
        filter: "",
        integrationKey: "",
        startTime: "",
        endTime: "",
      },
      searchNotice: {
        filter: "",
        startTime: "",
        endTime: "",
        notice: true,
      },
      searchExpired: {
        filter: "",
        startTime: "",
        endTime: "",
        expired: true,
      },
      statusList: [
        { value: 0, label: this.$t("yearCheck.result.noPass") },
        { value: 1, label: this.$t("yearCheck.result.pass") },
      ],
    };
  },
  mounted() {
    this.getList(1);
    this.getIntegrationList();
  },
  methods: {
    getList(pageNum) {
      if (this.$refs.tabs.currentName === "0") {
        this.handleRecentOverdue();
        this.$refs.vmTable.getList(pageNum);
        this.loading = false;
      }
      if (this.$refs.tabs.currentName === "1") {
        this.$refs.noticeTable.getList(pageNum);
        this.noticeLoading = false;
      }
      if (this.$refs.tabs.currentName === "2") {
        this.$refs.expiredTable.getList(pageNum);
        this.expiredLoading = false;
      }
      this.getNoticeTotal();
      this.getExpiredTotal();
    },
    handleTabClick(tab) {
      if (tab.index === "0") {
        this.$refs.vmTable.getList(1);
        this.loading = false;
      }
      if (tab.index === "1") {
        this.$refs.noticeTable.getList(1);
        this.noticeLoading = false;
      }
      if (tab.index === "2") {
        this.$refs.expiredTable.getList(1);
        this.expiredLoading = false;
      }
      this.getNoticeTotal();
      this.getExpiredTotal();
    },
    exportExcel() {
      this.exportLoading = true;
      let params = {
        integrationKey: this.search.integrationKey,
        startTime: this.dateRange != null ? this.dateRange[0] : "",
        endTime: this.dateRange != null ? this.dateRange[1] : "",
        isCn: this.$i18n.isCn ? 1 : 0,
        filter: this.search.filter,
      };
      this.$api
        .exportFile("yearChecks/export", params, 60)
        .then(res => {
          this.exportLoading = false;
          let blob = new Blob([res.data]);
          let fileName = "";
          if (this.$i18n.isCn) {
            fileName = "年检记录.xlsx";
          } else {
            fileName = "YearCheck Info.xlsx";
          }
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob, fileName);
          } else {
            let a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.download = fileName;
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            URL.revokeObjectURL(a.href);
            document.body.removeChild(a);
          }
        })
        .catch(() => {
          this.exportLoading = false;
        });
    },
    getIntegrationList() {
      this.$http.get("integrations/list").then(({ data }) => {
        this.integrationList = data;
      });
    },
    handleAdd() {
      this.$refs.editPage.open(0);
    },
    handleEdit(row) {
      this.$refs.editPage.open(row.id);
    },
    handleCheck(row) {
      this.$refs.editPage.checkRow(row);
    },
    getNoticeTotal() {
      let param = {
        notice: true,
      };
      this.$http.get(moduleName, param).then(res => {
        this.noticeTotal = res.data.total;
      });
    },
    getExpiredTotal() {
      let param = {
        expired: true,
      };
      this.$http.get(moduleName, param).then(res => {
        this.expiredTotal = res.data.total;
      });
    },

    handleDelete(row) {
      this.$confirm(this.$t("common.delete") + " " + this.$t("yearCheck.yearCheckNo") + " " + row.yearCheckNo + "," + this.$t("common.isDelete") + "?", this.$t("common.tips"), {
        confirmButtonText: this.$t("common.confirm"),
        cancelButtonText: this.$t("common.cancel"),
        type: "warning",
      }).then(() => {
        this.$api.deleteById(moduleName, row.id).then(res => {
          this.getList(1);
          this.$message.success(this.$t("common.tip.deleteSuccess"));
        }).catch(() => {
          this.$message.error(this.$t("common.tip.deleteError"));
        });
      });
    },
    handleDateChange(dateRange) {
      if (dateRange != null) {
        this.search.startTime = dateRange[0];
        this.search.endTime = dateRange[1];
      } else {
        this.search.startTime = "";
        this.search.endTime = "";
      }
    },
    handleNoticeDateChange(dateRange) {
      if (dateRange != null) {
        this.searchNotice.startTime = dateRange[0];
        this.searchNotice.endTime = dateRange[1];
      } else {
        this.searchNotice.startTime = "";
        this.searchNotice.endTime = "";
      }
    },
    handleExpiredDateChange(dateRange) {
      if (dateRange != null) {
        this.searchExpired.startTime = dateRange[0];
        this.searchExpired.endTime = dateRange[1];
      } else {
        this.searchExpired.startTime = "";
        this.searchExpired.endTime = "";
      }
    },
    clearDateRange() {
      this.dateRange = [];
    },
    clearNoticeDateRange() {
      this.noticeDateRange = [];
    },
    clearExpiredDateRange() {
      this.expiredDateRange = [];
    },
    handleRecentOverdue() {
      if (this.$route.params.recentOverdue != null && this.$route.params.recentOverdue) {
        let today = new Date();
        let year = today.getFullYear() - 1;
        let month = today.getMonth() + 1;
        let day = today.getDate();
        if (day < 10) {
          day = "0" + day;
        }
        if (month < 10) {
          month = "0" + month;
        }
        let endTime = year + "-" + month + "-" + day;
        let lastMonth = new Date(year, parseInt(month.toString()) - 2, parseInt(day.toString()));
        let lastMonthYear = lastMonth.getFullYear();
        let lastMonthMonth = lastMonth.getMonth() + 1;
        let lastMonthDay = lastMonth.getDate();
        if (lastMonthDay < 10) {
          lastMonthDay = "0" + lastMonthDay;
        }
        if (lastMonthMonth < 10) {
          lastMonthMonth = "0" + lastMonthMonth;
        }
        let startTime = lastMonthYear + "-" + lastMonthMonth + "-" + lastMonthDay;
        this.dateRange = [startTime, endTime];
        this.search.startTime = startTime + " 00:00:00";
        this.search.endTime = endTime + " 23:59:59";
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
